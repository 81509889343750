<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            价格保护
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <p>ABOUT JINTENG...</p>
                <br>
                <p>津腾实验设备有限公司坐落在天津高新区，成立于193年。已通过SO001∶2015质量管理体系认证，SO14001∶2015环境管理体系认证，RoSH检测以及欧盟德国TUV蔡茵颁发的CE认证。
                    拥有隔膜真空泵、微生物限度仪、溶剂过滤器、多联过滤器、针筒式滤膜过滤器、微孔滤膜六种系列自有产品。津腾通过严把生产过程、考究问题所在、制造良好的生产环境;同时引进进口
                    加工设备及先进的流水线，重点原料采用欧洲等国生产并得技术支持，确保产品质量的稳定性。
                </p>
                <br>
                <p>ABOUT JINTENG...</p>
                <br>
                <p>津腾实验设备有限公司坐落在天津高新区，成立于193年。已通过SO001∶2015质量管理体系认证，SO14001∶2015环境管理体系认证，RoSH检测以及欧盟德国TUV蔡茵颁发的CE认证。
                    拥有隔膜真空泵、微生物限度仪、溶剂过滤器、多联过滤器、针筒式滤膜过滤器、微孔滤膜六种系列自有产品。津腾通过严把生产过程、考究问题所在、制造良好的生产环境;同时引进进口
                    加工设备及先进的流水线，重点原料采用欧洲等国生产并得技术支持，确保产品质量的稳定性。
                </p>
                <br>
                <p>ABOUT JINTENG...</p>
                <br>
                <p>津腾实验设备有限公司坐落在天津高新区，成立于193年。已通过SO001∶2015质量管理体系认证，SO14001∶2015环境管理体系认证，RoSH检测以及欧盟德国TUV蔡茵颁发的CE认证。
                    拥有隔膜真空泵、微生物限度仪、溶剂过滤器、多联过滤器、针筒式滤膜过滤器、微孔滤膜六种系列自有产品。津腾通过严把生产过程、考究问题所在、制造良好的生产环境;同时引进进口
                    加工设备及先进的流水线，重点原料采用欧洲等国生产并得技术支持，确保产品质量的稳定性。
                </p>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PriceProtection"
}
</script>

<style scoped>

</style>
